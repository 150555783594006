import React, {FC, useEffect, useState} from "react";
import {Box, Button, Grid, Paper, Skeleton, Stack, Typography} from "@mui/material";
import {ActivityInfo, Watcher} from "./types";
import {useAuthDispatch} from "./AuthContext";
import {useWatcherDispatch} from "./WatcherContext";
import {Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis} from "recharts";
import Measure from "react-measure";

const WatcherSelectView: FC = () => {

    const [watchers, setWatchers] = useState<Watcher[]>([]);

    const watcherDispatch = useWatcherDispatch();
    const authDispatch = useAuthDispatch();

    const [currentDataMap, setCurrentDataMap] = useState<{[key: number]: ActivityInfo}>()
    const [graphWidth, setGraphWidth] = useState();

    useEffect(() => {
        fetch(`/api/watcher`, {
            credentials: 'include',
        })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    authDispatch({type: 'fail'})
                }
            })
            .then(data => {
                setWatchers(data);
            })
            .catch(reason => {
                console.log(`api error: ${reason}`)
            })
    }, [])

    useEffect(() => {
            if (!watchers) {
                return
            }
            fetch(`/api/activity/current`, {
                credentials: 'include',
            })
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        authDispatch({type: 'fail'})
                    }
                })
                .then(data => {
                    const currentDataMap = {}
                    data.forEach((activityInfo: ActivityInfo) => {
                        currentDataMap[activityInfo.watcherId] = activityInfo;
                    })
                    setCurrentDataMap(currentDataMap);
                })
                .catch(reason => {
                    console.log(`api error: ${reason}`)
                })
            const timer = setInterval(() => {
                fetch(`/api/activity/current`)
                    .then(res => {
                        if (res.ok) {
                            return res.json()
                        }
                    })
                    .then(data => {
                        const currentDataMap = {}
                        data.forEach((activityInfo: ActivityInfo) => {
                            currentDataMap[activityInfo.watcherId] = activityInfo;
                        })
                        setCurrentDataMap(currentDataMap);
                    })
                    .catch(reason => {
                        console.log(`api error: ${reason}`)
                    })
            }, 30000)
            return () => {
                clearInterval(timer);
            }
        },
        [watchers]
    );


    const selectWatcher = (watcher) => {
        watcherDispatch({type: 'set', watcher: watcher})
    }

    return (
        <Box>
            <Grid container padding={2} spacing={2}>
                {watchers.map(watcher => (
                    <Grid key={watcher.id} item xs={12} sm={6} md={4}>
                        <Paper sx={{p: 2}} onClick={() => selectWatcher(watcher)}>
                            <Stack spacing={1}>
                                <Measure bounds
                                         onResize={contentRect => setGraphWidth(contentRect.bounds.width)}>
                                    {({measureRef}) => (
                                        <Box ref={measureRef}/>
                                        )
                                    }
                                </Measure>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Typography variant={'h6'}>{watcher.name}</Typography>
                                    <Button onClick={() => selectWatcher(watcher)}>Открыть</Button>
                                </Stack>
                                {currentDataMap &&
                                    <BarChart data={currentDataMap[watcher.id]?.data}
                                              height={300} width={graphWidth}>
                                        <CartesianGrid/>
                                        <XAxis key={'hour'}/>
                                        <YAxis key={'activityDji'}/>
                                        <Legend/>
                                        <Bar dataKey={'activityDji'} name={'DJI'} fill={'#405dd1'}/>
                                        <Bar dataKey={'activityFpv'} name={'FPV'} fill={'#69c969'}/>
                                    </BarChart>
                                }
                                {!currentDataMap &&
                                    <Skeleton height={300}/>
                                }
                            </Stack>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default WatcherSelectView;
