import React, {useEffect, useState} from "react";
import {FC} from "react";
import {useWatcher, useWatcherDispatch} from "./WatcherContext";
import WatcherInfoView from "./WatcherInfoView";
import WatcherSelectView from "./WatcherSelectView";
import {useAuthDispatch} from "./AuthContext";

const WatchersView: FC = () => {

    const [loading, setLoading] = useState(true);
    const watcher = useWatcher();
    const authDispatch = useAuthDispatch();
    const watcherDispatch = useWatcherDispatch();

    useEffect(() => {
        if (watcher) {
            return
        }
        const watcherId = localStorage.getItem('watcherId');
        if (watcherId) {
            setLoading(true);
            fetch(`/api/watcher/${watcherId}`, {
                credentials: 'include',
            })
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        authDispatch({type: 'fail'})
                    }
                })
                .then(data => {
                    watcherDispatch({type: 'set', watcher: data});
                })
                .catch(reason => {
                    console.log(`api error: ${reason}`)
                })
                .finally(() => setLoading(false))
        } else {
            setLoading(false)
        }
    }, []);

    return loading ?
        <></>
        :
        watcher ?
            <WatcherInfoView/>
            :
            <WatcherSelectView/>

}

export default WatchersView;
