import React from 'react';
import {Box, CircularProgress} from "@mui/material";

const Loading: React.FC = () => {
    return (
        <Box sx={{
            transition: 'all 0.2s ease-in-out',
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            zIndex: 9999,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <CircularProgress color="primary" size={60} />
        </Box>
    );
};

export default Loading;
