import React, {FC, useEffect, useState} from "react";
import {Box, Button, FormControl, FormHelperText, FormLabel, OutlinedInput, Stack, Typography} from "@mui/material";
import {useAuthDispatch} from "./AuthContext";
import Loading from "./Loading";


const LoginView: FC = (props) => {
    const authDispatch = useAuthDispatch();

    const [username, setUsername] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [checking, setChecking] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loginFail, setLoginFail] = useState(false)

    const checkAuth = () => {
        setChecking(true)
        fetch(`/api/check-auth`, {
            credentials: 'include',
        }).then(res => {
            if (res.ok) {
                return res.text()
            } else {
                authDispatch({type: 'fail'})
            }
        }).then(role => {
            authDispatch({type: 'success', role: role})
        }).finally(() => {
            setLoading(false)
            setChecking(false)
        })
    }

    useEffect(() => {
        checkAuth()
    }, [])

    const checkLogin = () => {
        setLoading(true)
        setLoginFail(false)
        const authData = new FormData()
        authData.append('username', username);
        authData.append('password', password)
        fetch(`/auth`, {
            method: 'post',
            body: authData,
            credentials: 'include',
        }).then(res => {
            if (res.ok) {
                checkAuth()
            } else {
                authDispatch({type: 'fail'})
                setLoginFail(true)
            }
        }).finally(() => setLoading(false))
    }

    return (
        <>
            {loading && <Loading/>}
            <Box width={1} paddingTop={5}>
                <Stack marginX={'auto'} width={{md: 300, sm: 0.5, xs: 0.9}}>
                    {/*<img*/}
                    {/*    src={'images/nevsky.png'}*/}
                    {/*    alt={'nevsky'}*/}
                    {/*/>*/}
                    {!checking && <>
                        <FormControl>
                            <FormLabel htmlFor={'login'}>логин</FormLabel>
                            <OutlinedInput
                                id={'login'}
                                value={username}
                                disabled={loading}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel htmlFor={'password'}>пароль</FormLabel>
                            <OutlinedInput
                                id={'password'} type={"password"}
                                value={password}
                                disabled={loading}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {loginFail && <Typography color={'error'}>Неверные логин или пароль</Typography>}
                        </FormControl>
                        <FormControl>
                            <Button
                                onClick={checkLogin}
                                disabled={loading}>
                                Войти</Button>
                        </FormControl>
                    </>}
                </Stack>
            </Box>
        </>
    )
}

export default LoginView;