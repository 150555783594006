import React, {FC} from "react";
import {MapContainer, TileLayer} from 'react-leaflet'
import {Box, Grid, Stack, Typography} from "@mui/material";
import "leaflet/dist/leaflet.css";


const MapView: FC = () => {


    return (
        <Box height={1}>
            <Stack borderBottom={1} p={1}
                   borderColor={'divider'} spacing={4}
                   flexDirection={"row"}
                   alignItems={'baseline'}>
                <Typography variant={"h6"}>Обстановка</Typography>
            </Stack>
            <Grid container height={0.9}>
                <Grid item xs={12} height={1} overflow={'hidden'}>
                    <MapContainer center={[48.688071, 38.022725]} zoom={13}
                                  style={{height: 600}}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </MapContainer>
                </Grid>
            </Grid>
        </Box>
    )
}

export default MapView
