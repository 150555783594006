import React, {FC, lazy, Suspense, useState} from "react";
import {Grid, IconButton, Paper, Stack, Tooltip, Typography} from "@mui/material";
import WatcherInfoMenu from "./WatcherInfoMenu";
import Loading from "./Loading";
import FlybysView from "./FlybysView";
import ActivityInfoView from "./ActivityInfoView";
import DetectionsView from "./DetectionsView";
import {useWatcher, useWatcherDispatch} from "./WatcherContext";
import MapView from "./MapView";
import {AppsOutlined} from "@mui/icons-material";

const WatcherInfoView: FC = () => {
    const [view, setView] = useState('flybys')

    const StatsView = lazy(() => import("./StatsView"));

    const watcher = useWatcher();
    const watcherDispatch = useWatcherDispatch();


    const goSelectWatcher = () => {
        watcherDispatch({type: 'unset'})
    }

    return (
        <Grid container padding={1} spacing={1} height={1} overflow={'hidden'}>
            <Grid item sm={2} xs={3} height={1}>
                <Paper>
                    <Stack flexDirection={'row'}
                           padding={1}
                           justifyContent={'space-between'}
                           alignItems={'flex-start'}>
                        <Typography variant={'h6'}>{watcher?.name}</Typography>
                        <Tooltip title={'Выбрать пункт наблюдения'}>
                            <IconButton
                                size={'small'}
                                onClick={goSelectWatcher}>
                                <AppsOutlined/>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Paper>
                <Paper sx={{height: 0.90, mt: 1}}>
                    <WatcherInfoMenu onSelect={setView}/>
                </Paper>
            </Grid>
            <Grid item sm={10} xs={9} height={1}>

                <Paper sx={{height: 0.99}}>
                    <Suspense fallback={<Loading/>}>
                        {
                            (view === 'flybys' && <FlybysView/>)
                            ||
                            (view === 'activity' && <ActivityInfoView/>)
                            ||
                            (view === 'detections' && <DetectionsView/>)
                            ||
                            (view === 'stats' && <StatsView/>)
                            ||
                            (view === 'map' && <MapView/>)
                            ||
                            <></>
                        }
                    </Suspense>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default WatcherInfoView;
