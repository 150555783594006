import React, {useState} from "react";
import {FC} from "react";
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import {Flyby} from "./types";
import dayjs from "dayjs";
import {Close} from "@mui/icons-material";
import {CartesianGrid, Line, LineChart, YAxis} from "recharts";

const dateOf = (flyby: Flyby) => {
    return dayjs(flyby.dateTimeFrom).format('DD-MM-YY')
}


const formatDates = (flyby: Flyby) => {
    const dateFrom = dayjs(flyby.dateTimeFrom)
    const dateTo = dayjs(flyby.dateTimeTo)
    if (flyby.powers.length == 1) {
        return dateFrom.format('DD-MM-YY HH:mm:ss');
    }
    const diffHours = dateTo.diff(dateFrom, 'hour')
    const diffMinutes = dateTo.diff(dateFrom, 'minute') % 60
    const diffSeconds = dateTo.diff(dateFrom, 'second') % 60
    const diff = `${diffHours.toString().padStart(2, "0")}:${diffMinutes.toString().padStart(2, "0")}:${diffSeconds.toString().padStart(2, "0")}`
    return `${dateFrom.format('HH:mm:ss')} - ${dateTo.format('HH:mm:ss')} (${diff})`
}


interface Props {
    flyby: Flyby,
    onClose: () => void,
}

const FlybyInfo: FC<Props> = ({flyby, onClose}) => {
    const [open, setOpen] = useState(true)

    const handleClose = () => {
        setOpen(false)
        onClose()
    }
    return (
        <Dialog open={open}
            onClose={handleClose}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                {`${flyby.type}  ${flyby.names.join(", ")}`}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <Close />
            </IconButton>
            <DialogContent>
                <Box width={500} height={300}>
                    <Grid container>
                        <Grid item xs={4}>
                            <Typography>Дата пролёта</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography>{dateOf(flyby)}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>Время пролёта</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography>{formatDates(flyby)}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>Частоты</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Stack spacing={1} overflow={'hidden'}
                                   direction={'row'}>{flyby.freqs.map((freq) => <Chip label={freq}
                                                                                      size={'small'}/>)}</Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Изменение мощности сигнала</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <LineChart data={flyby.powers.map((power) => ({"p": power}))}
                                       compact={true}
                                       height={100}
                                       width={500}>
                                <YAxis dataKey="p"/>
                                <CartesianGrid />
                                <Line type={"monotone"}
                                      dot={false}
                                      isAnimationActive={false}
                                      dataKey="p"/>
                            </LineChart>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default FlybyInfo;
