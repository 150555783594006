import React, {FC, useState} from "react";
import {Box, ListItemText, MenuItem, MenuList} from "@mui/material";
import {useAuth} from "./AuthContext";
import {useWatcher} from "./WatcherContext";

interface Props {
    onSelect: (string) => void
}

const WatcherInfoMenu: FC<Props> = (props) => {
    const {onSelect} = props;

    const [selectedMenuItem, setSelectedMenuItem] = useState<string>('flybys')

    const auth = useAuth()
    const watcher = useWatcher();


    const menuItems = {
        'flybys': "Пролёты БПЛА",
        'activity': "Активность БПЛА",
        'detections': "Обнаружения БПЛА",
        'stats': "Статистика",
        'map': "Обстановка",
    }

    const handleSelect = (selected: string) => {
        setSelectedMenuItem(selected)
        onSelect(selected)
    }

    return (
        <Box overflow={'hidden'}>
            <Box borderBottom={1} p={1} borderColor={'divider'}>
                <Box alignItems={'center'} justifyContent={'center'} display={'flex'} flexDirection={'column'}>
                    <img src={`images/${watcher?.logo || 'nevsky.png'}`}/>
                    {/*<img src={'images/post_rer.png'}/>*/}
                </Box>
            </Box>
            <MenuList  sx={{ height: 0.5, overflowY: 'auto'}}>
                {Object.keys(menuItems).map(menuItem => (
                    <MenuItem
                        key={menuItem}
                        onClick={() => handleSelect(menuItem)}
                        selected={menuItem === selectedMenuItem}
                        divider>
                        <ListItemText>{menuItems[menuItem]}</ListItemText>
                    </MenuItem>
                ))}
            </MenuList>
        </Box>
    )
}

export default WatcherInfoMenu;
