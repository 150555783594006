import React, {FC, useEffect, useRef, useState} from "react";
import {Box, CircularProgress, Grid, ListItem, MenuItem, MenuList, Stack, Typography} from "@mui/material";
import {Detection} from "./types";
import dayjs from "dayjs";
import {useAuthDispatch} from "./AuthContext";
import {useWatcher} from "./WatcherContext";

require('dayjs/locale/ru')

const DetectionsView: FC = () => {
    const [loading, setLoading] = useState(false);
    const [newDetections, setNewDetections] = useState<Detection[]>([])
    const [detections, setDetections] = useState<Detection[]>([])
    const lastDateTime = useRef()

    const authDispatch = useAuthDispatch();
    const watcher = useWatcher();

    useEffect(() => {
        if (!watcher) {
            return
        }
        setLoading(true)
        fetch(`/api/detection?watcherId=${watcher.id}`, {
            credentials: 'include',
        })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    authDispatch({type: 'fail'})
                }
            })
            .then(data => {
                setDetections(data);
                lastDateTime.current = data[0].dateTime
            })
            .catch(reason => {
                console.log(`api error: ${reason}`)
            })
            .finally(() => setLoading(false))
        const timer = setInterval(() => {
            setLoading(true)
            console.log(`reading detections after ${lastDateTime.current}`)
            const watcherParam = `?watcherId=${watcher.id}`
            const afterParam = lastDateTime.current ?
                `&after=${dayjs(lastDateTime.current).format('YYYY-MM-DDTHH:mm:ss.SSS')}` : ``;
            console.log(`params: ${watcherParam}${afterParam}`)
            fetch(`/api/detection${watcherParam}${afterParam}`)
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    }
                })
                .then(data => {
                    if (lastDateTime.current) {
                        if (data.length > 0) {
                            setNewDetections(data)
                            if (data) {
                                setDetections(prev => [...data, ...prev])
                                lastDateTime.current = data[0].dateTime
                            }
                        }
                    } else {
                        setDetections(data);
                    }
                })
                .catch(reason => {
                    console.log(`api error: ${reason}`)
                })
                .finally(() => setLoading(false))
        }, 15000)
        return () => {
            clearInterval(timer)
        }
    }, [watcher])

    return (
        <Box height={1}>
            <Stack borderBottom={1} p={1} borderColor={'divider'} spacing={2} flexDirection={"row"}>
                <Typography variant={"h6"}>Обнаружения БПЛА</Typography>
                {loading && <CircularProgress color="primary" size={14}/>}
            </Stack>

            <Grid container spacing={1} height={0.93}>
                <Grid item xs={12} height={1}>
                    <Box borderBottom={1} p={1} borderColor={'divider'}>
                        <Grid container>
                            <Grid item xs={2}>
                                <Typography>Время</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography>Тип</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography>Частота</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography>Наименование</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography>Мощность сигнала</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <MenuList sx={{height: 1, overflowY: 'auto'}}>
                        {
                            detections.map(detection => (
                                <MenuItem
                                    key={detection.id}
                                    // selected={newdetections.indexOf(detection) > -1}
                                    divider
                                    disableRipple
                                    sx={{
                                        padding: 0,
                                        backgroundColor: newDetections.indexOf(detection) > -1 ? 'lightgreen' : 'inherit'
                                    }}
                                >
                                    <ListItem>
                                        <Grid container padding={0}>
                                            <Grid item xs={2}>
                                                <Typography>{dayjs(detection.dateTime).format('DD-MM-YY HH:mm:ss')}</Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography>{detection.type}</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography>{detection.freq}</Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography>{detection.name}</Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography>{detection.power}</Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                </MenuItem>
                            ))
                        }
                    </MenuList>
                </Grid>
            </Grid>
        </Box>
    )
}

export default DetectionsView;
